@charset "UTF-8";

// 新SPデザインにおける色の定義をここに書く

// Base色（アイコンとセット）
$base-color: #1d3850;

// 強調・赤
$strong-red: #e40007;

// 強調・青（予約等）
$strong-blue: #0062c4;

// 強調・進行（適用・完了等）
$strong-green: #118800;

// 弱調・灰
$weak-gray: #999999;

// 濃い灰
$dark-gary: #2f2f2f;

// 基本文字色
$base-text-color: $dark-gary;

// LINK色（テキストのみ）
$base-link-color: #1d4a6d;

// 背景
$background-color: #eef0f2;

// 背景：白
$background-white-color: #fff;

// ボーダー
$border-color: #ccc;

// ボーダー：破線
$border-dashed-color: #c0c0c0;

// 矢印
$arrow-color: #666;

// ボタン無効時：テキスト
$disable-btn-text-color: #ccc;

// ボタン無効時：ボタン
$disable-btn-bg-color: #eef0f2;

// ページ遷移用・オレンジ色の三角
$page-link-triangle-color: #F2800E;


/**
 キーカラー関連
 */
// menuバッジカラー
$menu-badge-color: #e9137a;

// アクティブ
$active-color: #159F05;
$active-text-color: #118800;
$active-background-color: #e8f5e6;

// 読み放題トップキーカラー
$sbsc-key-color: #009844;

// 無料トップキーカラー
$free-key-color: #e84949;

// マンガキーカラー
$manga-key-color: #0094d6;

// ラノベキーカラー
$light-novel-key-color: #ffa91c;

// 文芸・ビジネスキーカラー
$literature-business-key-color: #5a1818;

// 個人出版
$indies-key-color: #82cbd1;

// R18キーカラー
$r18-key-color: #f26785;

// BLキーカラー
$bl-key-color: #e84eaa;

// TLキーカラー
$tl-key-color: #a74ad3;

// カート周り：基本カラー
$primary-color: #f2800e;

// カート周り：第2カラー
$secondary-color: #5a8d48;

//会員登録ボタン・今すぐ購入ボタンは同じオレンジ。今後分けたくなった時用に分離
// 会員登録ボタンカラー
$sign-in-btn-color: $primary-color;
$sign-in-btn-shadow-color: #935815;
// 今すぐ購入・まとめて支払ボタンカラー
$purchase-btn-color: $primary-color;

// カートボタン・会員登録ボタンカラー（緑）
$cart-btn-color: $secondary-color;
$cart-btn-shadow-color: #0D2D1C;

// まる読みボタンカラー
$maruyomi-btn-color: #FF8DB8;
$maruyomi-btn-shadow-color: #DD3173;

// sale、期間限定のカラー
$sale-color: #E7370E;

// ギフトボタンのカラー
$gift-btn-color: #93BC4E;

// box-shadowのカラー：黒
$shadow-black-color: #000;

// パーツ用カラー

// 評価用★カラー
$stars-color: #FFC84D;
// 自分の★カラー TODO: 確認
$my-stars-color: #1484D6;

// ランキング用カラー
// 1位
$first-rank-color: #DEBA23;

// 2位
$second-rank-color: #B6C1CD;

// 3位
$third-rank-color: #A27B48;

// 4位以下(弱調・灰と同じだが一応分離)
$other-rank-color: #999999;

// 特典タグカラ－
$gift-color: #9100C4;

// セット商品タグカラ－
$set-color: #167C27;

// 試読増量タグカラー
$increase-color: #FF7519;

// コインアップタグ（横長）カラー
$coin-up-tag-color: #EA003A;

// NEWタグカラー
$new-tag-color: #F6ED00;

// 読み放題タグカラー
$sbsc-tag-color: #614CBD;

// 登録済み・既読タグ背景カラー
$status-tag-bg-color: #eeeeee;

// 購入済の色 （背景色より少し濃いグレー、一応分離）
$purchased-color: #dbdbdb;

// カレンダー
$calender-bg-color: #F9F9F9;
// 土曜日
$saturday-bg-color: #F2F9FF;
// 日曜日
$sunday-bg-color: #FFF0F0;


// 読み放題TOP用カラーを以下に記載
// 背景色
$sbsc-bg-color: #eef0f2;
// ボーダー
$sbsc-border-color: #CBCED1;
// グレーの陰
$sbsc-gray-shadow: #CBCED1;
// 濃い目のグレー
$sbsc-weak-gray: #999999;
// リンク色・紺
$sbsc-base-link-color: #1D4A6D;
// リンク職・濃紺
$sbsc-link-color: #1d3850;
// 読むボタンのベース色
$sbsc-orange: #f78e00;
// 主に読むボタンの陰
$sbsc-orange-shadow: #cc7600;
// hover時の明るめのオレンジ
$sbsc-orange-hover: #FFAB39;
// 読むボタン以外のhover
$sbsc-blue-hover: #007AFF;


// 他社ロゴ・アイコン用カラー
// はてなブックマーク
$hatebu-color: #00a4de;

// X（旧Twitter）
$x-color: #313131;

// Facebook
$facebook-color: #1877f2;

// LINE
$line-color: #00b900;


//話・連載用カラー
// プライマリーカラー（blue）
$ttsk-blue-color: #00a4e5;
$ttsk-light-blue-color: #66d4ff;
$ttsk-dark-blue-color: #1278a1;
$ttsk-medium-dark-blue-color: #0092cc;
$ttsk-light-blue-01-color: #9de0fb;
$ttsk-light-blue-02-color: #cef0fd;
$ttsk-light-blue-03-color: #e4f6fd;

// セカンダリーカラー（yellow）
$ttsk-yellow-color: #f0ac00;
$ttsk-light-yellow-color: #ffd166;
$ttsk-dark-yellow-color: #a17112;
// エラーカラー（red）
$ttsk-red-color: #eb3f00;
$ttsk-light-red-color: #ff8f66;
$ttsk-dark-red-color: #aa3b13;
// サクセスカラー（green）
$ttsk-green-color: #00a889;
$ttsk-light-green-color: #85e0d0;
$ttsk-dark-green-color: #0d7360;
// ニュートラルカラー（blue-gray）
$ttsk-blue-gray-color: #527898;
$ttsk-light-blue-gray-color: #9cb4c9;
$ttsk-dark-blue-gray-color: #455d73;
$ttsk-light-blue-gray-01-color: #f7fafd;
$ttsk-light-blue-gray-02-color: #dae6f1;
$ttsk-light-blue-gray-03-color: #e8f1f9;

// テキストカラー
$ttsk-text-color: #333;
$ttsk-light-text-color: #666;
// グレー（gray）
$ttsk-bg-gray-color: #f2f2f2;
$ttsk-outline-color: #d9e4ee;
// ボーダーカラー（blue-gray）
$ttsk-border-color: $ttsk-light-blue-gray-02-color;

// 一覧ページ用カラー
// ボーダー
$info-border-color: #EEF0F2;
// ホバー
$info-hover-color: #EEF0F2;

// 館カラー
$key-colors: (
	shinjuku: $manga-key-color,
	yaesu: $literature-business-key-color,
	akihabara: $light-novel-key-color,
	newuser: $indies-key-color,
	ikebukuro: $bl-key-color,
	ikebukuro-tl: $tl-key-color,
	otona: $r18-key-color
);

/* ==========================================================================
   読み放題（tom）のカラー一部 ※必要な変数のみ追加
   ========================================================================== */
// 読み放題（tom）のカラー
// ブルー系
$sbsc-blue: #0075ff;
$sbsc-dark-blue: #0063d7;
$sbsc-pale-blue: #eaf3ff;
$sbsc-blue-gray: #7488aa;

// レッド系
$sbsc-red: #ee426c;
$sbsc-dark-red: #d91b49;
$sbsc-light-red: #ffe8ed;

// ピンク系
$sbsc-pink: #ff3e9a;
$sbsc-dark-pink: #f80278;

// オレンジ系
$sbsc-orange: #ff8f0b;
$sbsc-dark-orange: #f47500;

//　イエロー系
$sbsc-yellow: #ffd105;
