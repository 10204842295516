// Google Material Icons
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons%7CMaterial+Icons+Outlined');

// Google Material Symbols
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');


// 以下のwoff等はasset/vendor/に置いている
@font-face {
  font-family: 'bookwalker';
  src:  url('/v/bob/fonts/fonts/8fb5ebde208704f6a0eb3b0847b63e2cf36879d9/bookwalker.eot');
  src:  url('/v/bob/fonts/fonts/8fb5ebde208704f6a0eb3b0847b63e2cf36879d9/bookwalker.eot?#iefix') format('embedded-opentype'),
  url('/v/bob/fonts/fonts/68f73061c841a8530aa60ebb745cc6f60cfe0e5c/bookwalker.ttf') format('truetype'),
  url('/v/bob/fonts/fonts/42939275580d0aabf2b739fcd862f146f14c8fe1/bookwalker.woff') format('woff'),
  url('/v/bob/fonts/fonts/b9643f70f5d51695c08c79403c0e19591d63d125/bookwalker.svg?#bookwalker') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="ico-"], [class*=" ico-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'bookwalker' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-funnel:before {
  content: "\e96f";
}
.ico-bin:before {
  content: "\e96d";
}
.ico-detail:before {
  content: "\e96e";
}
.ico-coin:before {
  content: "\e943";
}
.ico-textcopy:before {
  content: "\e969";
}
.ico-allread:before {
  content: "\e96a";
}
.ico-unread:before {
  content: "\e96b";
}
.ico-sort:before {
  content: "\e96c";
}
.ico-refresh:before {
  content: "\e949";
}
.ico-synchro:before {
  content: "\e968";
}
.ico-free:before {
  content: "\e91d";
}
.ico-bookshelf:before {
  content: "\e95c";
}
.ico-grid:before {
  content: "\e966";
}
.ico-minus:before {
  content: "\e964";
}
.ico-plus:before {
  content: "\e965";
}
.ico-angle-right-W:before {
  content: "\e933";
}
.ico-angle-left-W:before {
  content: "\e934";
}
.ico-angle-top-W:before {
  content: "\e962";
}
.ico-angle-down-W:before {
  content: "\e963";
}
.ico-readingbook:before {
  content: "\e960";
}
.ico-purchase_history:before {
  content: "\e961";
}
.ico-cooperation:before {
  content: "\e95f";
}
.ico-external-link:before {
  content: "\e95e";
}
.ico-monthlyrank:before {
  content: "\e95d";
}
.ico-gift:before {
  content: "\e931";
}
.ico-star_empty:before {
  content: "\e967";
}
.ico-star_half:before {
  content: "\e958";
}
.ico-star:before {
  content: "\e913";
}
.ico-books:before {
  content: "\e904";
}
.ico-tag:before {
  content: "\e906";
}
.ico-book-prev:before {
  content: "\e915";
}
.ico-book-next:before {
  content: "\e918";
}
.ico-yen:before {
  content: "\e91c";
}
.ico-profile:before {
  content: "\e91e";
}
.ico-bookmark:before {
  content: "\e91f";
}
.ico-card:before {
  content: "\e920";
}
.ico-follow:before {
  content: "\e921";
}
.ico-purchase:before {
  content: "\e922";
}
.ico-balloon-comment:before {
  content: "\e923";
}
.ico-balloon-free:before {
  content: "\e924";
}
.ico-balloon-video:before {
  content: "\e925";
}
.ico-publisher:before {
  content: "\e926";
}
.ico-pc:before {
  content: "\e92a";
}
.ico-browser:before {
  content: "\e92c";
}
.ico-iphone:before {
  content: "\e92d";
}
.ico-android:before {
  content: "\e92e";
}
.ico-beginner:before {
  content: "\e92f";
}
.ico-genre:before {
  content: "\e930";
}
.ico-anchor:before {
  content: "\e932";
}
.ico-angle-up:before {
  content: "\e935";
}
.ico-angle-down:before {
  content: "\e936";
}
.ico-angle-left:before {
  content: "\e938";
}
.ico-angle-right:before {
  content: "\e939";
}
.ico-triangle_bottom:before {
  content: "\e93a";
}
.ico-triangle_top:before {
  content: "\e93b";
}
.ico-triangle_right:before {
  content: "\e93c";
}
.ico-triangle_left:before {
  content: "\e93d";
}
.ico-question:before {
  content: "\e93e";
}
.ico-exclamation:before {
  content: "\e93f";
}
.ico-attention:before {
  content: "\e940";
}
.ico-info:before {
  content: "\e941";
}
.ico-help:before {
  content: "\e942";
}
.ico-history:before {
  content: "\e927";
}
.ico-calendar:before {
  content: "\e944";
}
.ico-window:before {
  content: "\e945";
}
.ico-mail:before {
  content: "\e947";
}
.ico-cogs:before {
  content: "\e948";
}
.ico-arrow-rightm:before {
  content: "\e94a";
}
.ico-pie:before {
  content: "\e94b";
}
.ico-pie-2:before {
  content: "\e94c";
}
.ico-alerm-refresh:before {
  content: "\e956";
}
.ico-alerm:before {
  content: "\e957";
}
.ico-good:before {
  content: "\e959";
}
.ico-sp-menu:before {
  content: "\e900";
}
.ico-bars:before {
  content: "\e901";
}
.ico-book:before {
  content: "\e907";
}
.ico-book-2:before {
  content: "\e908";
}
.ico-book-like:before {
  content: "\e909";
}
.ico-book-search:before {
  content: "\e90a";
}
.ico-book-open:before {
  content: "\e90b";
}
.ico-series-set:before {
  content: "\e90c";
}
.ico-series-solo:before {
  content: "\e90d";
}
.ico-unread2:before {
  content: "\e90e";
}
.ico-googleplus:before {
  content: "\e910";
}
.ico-twitter:before {
  content: "\e911";
}
.ico-facebook:before {
  content: "\e912";
}
.ico-crown:before {
  content: "\e905";
}
.ico-box-open:before {
  content: "\e914";
}
.ico-pencil:before {
  content: "\e916";
}
.ico-author:before {
  content: "\e919";
}
.ico-bookmark-edit:before {
  content: "\e91a";
}
.ico-file:before {
  content: "\e91b";
}
.ico-entry-history:before {
  content: "\e95a";
}
.ico-label:before {
  content: "\e95b";
}
.ico-share-url:before {
  content: "\e946";
}
.ico-search:before {
  content: "\e902";
}
.ico-expansion:before {
  content: "\e903";
}
.ico-check:before {
  content: "\e928";
}
.ico-cart-in:before {
  content: "\e929";
}
.ico-cart:before {
  content: "\e954";
}
.ico-wrench:before {
  content: "\e955";
}
.ico-safety:before {
  content: "\e917";
}
.ico-loginout:before {
  content: "\e92b";
}
.ico-member:before {
  content: "\e94d";
}
.ico-NewItems:before {
  content: "\e94e";
}
.ico-SetBooks:before {
  content: "\e94f";
}
.ico-FeatureList:before {
  content: "\e950";
}
.ico-Coin-up:before {
  content: "\e951";
}
.ico-LimitedBooks:before {
  content: "\e952";
}
.ico-Zero-Books:before {
  content: "\e953";
}
.ico-close:before {
  content: "\e937";
}
.ico-return-app:before {
  content: "\e90f";
}
.ico-key:before {
  content: "\e970";
}
.ico-balloon-question:before {
   content: "\e971";
 }
.ico-medal-ribbon:before {
  content: "\e972";
}
.ico-grid-wide-space:before {
  content: "\e973";
}
.ico-subscription:before {
  content: "\e974";
}
.ico-launch:before {
  content: "\e975";
}
.ico-hash:before {
    content: "\e976";
}