// MA追従(PC)
@import "../../../../../bob/fonts/style";
@import "../../../sp/templates/ma-layout/ma-reset-mixin";
@import "../../../sp/atoms/z-index";
@import "../../../sp/atoms/colors";

.o-ma-side-floating__main {
    $button-clickable-area-size: 44px;
    $close-button-size: 26px;
    $button-margin: 8px;

    display: block;
    position: fixed;
    bottom: 10px;
    left: 10px;
    z-index: $footer-floating-box-z-index;
    
    &.close {
        display: none;
    }

    .o-ma-side-floating__main-inner {
        position: relative;
        box-shadow: 2px 2px 8px rgba(#000, 0.35);
    }

    img {
        width: 195px;
        vertical-align: bottom;
    }

    .o-ma-side-floating__close-button-wrap {
        position: absolute;
        top: calc((#{$close-button-size} - #{$button-margin}) - #{$button-clickable-area-size});
        right: calc((#{$close-button-size} - #{$button-margin}) - #{$button-clickable-area-size});
    }

    .o-ma-side-floating__close-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: $close-button-size;
        height: $close-button-size;
        border: 1px solid #fff;
        border-radius: 50%;
        background-color: rgba($dark-gary, 0.8);
        color: #fff;
        margin: 18px 18px 0 0;

        .ico.ico-close {
            font-size: 16px;
        }
    }
}
