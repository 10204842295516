// 各所のz-indexをいい加減集めて管理したい
// TODO: なんとなくz-indexの降順で並べる

//////////// GDPR（gdprは別管理なので、この変数は使っていないが区別しやすいように置いている）
$gdpr-z-index: 2147483647;

//////////// MAモーダル。GDPRの次に前面に出す必要あり。
$ma-modal-z-index: 92000;
$ma-modal-overlay-z-index: 91000;
$ma-modal-close: 100;

///////////// ヘッダーメニューを開いたとき
$header-on-z-index: 90000;

//////////// 予約購入ボタンモーダル（リストメニューより上）
$reserve-modal-z-index: 14000;

///////////// お知らせ用ダイアログ
$dialog-z-index: 13000;

////////////////// リストの設定メニュー（表示設定・並べ替え・条件変更）
$list-setting-menu-z-index: 12500;

////////////////// 絞り込み
$filter-save-box-z-index: 12010; // 絞り込み条件を保存
$filter-content-top-z-index: 12010;
$filter-z-index: 12000;
$filter-ttl-z-index: 15; // 絞り込み内の大項目

////////////////// 並び替えウィンドウ
$sort-z-index: 11500;

//////////// 操作完了時のポップアップ
$popup-z-index: 10500;

//////////// 汎用モーダル
$modal-z-index: 10210;

///////////// 共通の背景オーバーレイ
$overlay-z-index: 10200;

///////////// ヘッダー
$header-menu-close-button-z-index: 10150;
$header-content-top-z-index: 10110;
$header-content-z-index: 10100;
$header-overlay-z-index: 10010;
$header-z-index: 10000;
$header-menu-select-z-index: 2;

///////////// フローティングボタン（詳細ページ）
$floating-btn-z-index: 9900;

///////////// タブ（詳細ページ）
$detail-tab-z-index: 9910;

////////////// 「タイトルを全表示」トグルボタン周り
$toggle-btn-box-z-index: 9000;

////////////// タイトルを全表示した時のオーバーレイ
$tile-overlay-z-index: 1100;

////////////// 左下固定「お得な情報」/MA追従
$footer-floating-box-z-index: 1000;
$footer-coupon-button-z-index: 2;

////////////// カスタムセレクトボックス（JS）
$custom-selectbox-z-index: 2000;

//////////////// シリーズ表示用モーダル
$series-modal-arrow-z-index: 1300;
$series-modal-z-index: 1200;
$series-tile-overlay-z-index: 1100; // シリーズの時はこのオーバーレイをモーダルの後ろに持っていく（NOTE: 単品とシリーズが混ざると結局全オーバーレイをモーダルの後ろにもってこざるを得ないか？）

///////////////// タイル/カードアイテム関連とか書影関連とか
//　書影上のSALE、FREEアイコン
$label-sale-free-z-index: 20;
// 赤枠に×の閉じるボタン
$close-btn-z-index: 11;
//グレーに×の閉じるボタン
$delete-btn-z-index: 90;
// ラベル
$label-z-index: 10;
// 試し読み増アイコン
$label-trial-reading-z-index: 9;
// 書影上チェックボタン
$thumb-check-z-index: 10;

///////////////// 読み放題TOP用
$sbsc-bnr-z-index: 9500; // バナーhover用
$sbsc-slide-btn-z-index: 100; // 棚の左右のスライドボタン用

///////////////// タテスク用
// 赤枠に×の閉じるボタン
$ttsk-close-btn-z-index: 11;
// ラベル
$ttsk-label-z-index: 10;

/////////////////PC詳細ページ用
// 固定ナビ
$page-nav-z-index: 999;
//書影上ボタン
$book-item-btn-z-index: 90;
// 限定特典アイコン
$limited-icon-z-index: 10;

///////////////// キャンペーン用
// まとめ買い吹き出し （PC・SPの作品詳細、リスティング作品詳細、シリーズ）
$campaign-bulk-buy-upper-layer-z-index: 10;
$campaign-bulk-buy-under-layer-z-index: 9;
// 横断吹き出し （PC・SPの作品詳細、リスティング作品詳細）
$campaign-notice-upper-layer-z-index: 10;
$campaign-notice-under-layer-z-index: 9;
